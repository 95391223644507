import { Image } from "react-bootstrap";
import data from "../../assets/maskapai.json";
import dataAirport from "../../assets/airport.json";
import dataClass from "../../assets/class.json";

export function AirlineConverter(code) {
  const newData = data.find((o) => o.code === code);
  if (newData) {
    return (
      <div className="d-flex align-items-center">
        <Image
          style={{ maxWidth: "50px" }}
          src={`/assets/airlines/${newData?.code}.png`}
        />
        <div className="ms-2">{newData?.name}</div>
      </div>
    );
  } else {
    return code;
  }
}

export function AirlineNameConverter(code) {
  const newData = data.find((o) => o.code === code);
  if (newData) {
    return newData?.name;
  } else {
    return code;
  }
}

export function AirlineImageConverter(code) {
  const newData = data.find((o) => o.code === code);
  if (newData) {
    return (
      <Image
        style={{ maxWidth: "25px" }}
        className="me-1"
        src={`/assets/airlines/${newData?.code}.png`}
      />
    );
  }
}

export function AirportConverter(code) {
  const newData = dataAirport.find((o) => o.code === code);
  if (newData) {
    return newData?.name;
  } else {
    return code;
  }
}

export function CityConverter(code) {
  const newData = dataAirport.find((o) => o.code === code);
  if (newData) {
    return newData?.cityName;
  } else {
    return code;
  }
}

export function ClassConverter(code) {
  const newData = dataClass.find((o) => o.code?.indexOf(code) !== -1);
  if (newData) {
    return newData?.name;
  } else {
    return code;
  }
}
