import { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  AirlineConverter,
  AirlineImageConverter,
  AirlineNameConverter,
  AirportConverter,
  CityConverter,
} from "../../../../config/helper/maskapai";
import BaggageIcon from "../../../../assets/icons/baggage.svg";
import MealsIcon from "../../../../assets/icons/meals.svg";
import ClockIcon from "../../../../assets/icons/clock.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";

function ItemFlight(params) {
  const { t } = useTranslation();
  const [key, setKey] = useState("");

  return (
    (params?.data?.listclassgroup?.classgroup?.length > 0
      ? params?.data?.listclassgroup?.classgroup[0] !==
        "\n\t               \n\t            "
      : true) &&
    params?.data?.listclassgroup?.classgroup !==
      "\n\t               \n\t            " && (
      <Card className="w-100 border-0 rounded-4 shadow-sm flight-item mb-4">
        <Card.Body className="py-4">
          <Row className="align-item-center justify-content-center">
            <Col xs="12" sm="12" md="12" lg="4" xl="4">
              <div className="d-flex align-items-center h-100">
                <div className="ms-2 fs-5">
                  {params?.data?.listflight?.flight?.length > 1
                    ? params?.data?.listflight?.flight?.map((item, index) => {
                        if (index > 0) {
                          if (
                            item.airlinecode !==
                            params?.data?.listflight?.flight?.[index - 1]
                              ?.airlinecode
                          ) {
                            return AirlineConverter(
                              item.flightno.substring(0, 2)
                            );
                          }
                        } else {
                          return AirlineConverter(
                            item.flightno.substring(0, 2)
                          );
                        }
                      })
                    : AirlineConverter(
                        params?.data?.listflight?.flight?.flightno.substring(
                          0,
                          2
                        )
                      )}
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="5"
              xl="5"
              className="mt-auto mb-auto"
            >
              <div className="d-flex text-center justify-content-around">
                <div className="me-4">
                  <div className="fw-bold">
                    {params?.data?.listflight?.flight?.length > 1
                      ? params?.data?.listflight?.flight[0]?.departtime
                      : params?.data?.listflight?.flight?.departtime}
                  </div>
                  {/* <Badge className="mt-2" bg="grey">
                    {params?.data?.listflight?.flight?.fromcity}
                  </Badge> */}
                  <Badge className="mt-2" bg="grey">
                    {params?.data?.listflight?.flight?.length > 1
                      ? params?.data?.listflight?.flight[0]?.fromcity
                      : params?.data?.listflight?.flight?.fromcity}
                  </Badge>
                </div>
                <div className="me-4 fw-lighter">
                  <div>
                    {params?.data?.durationhour}j {params?.data?.durationminute}
                    m
                  </div>
                  <div className="line-flight"></div>
                  <div className="mt-2">
                    {params?.data?.listflight?.flight?.length > 1
                      ? params?.data?.listflight?.flight?.length + " Transit"
                      : "Direct"}
                  </div>
                </div>
                <div>
                  <div className="fw-bold">
                    {params?.data?.listflight?.flight?.length > 1
                      ? params?.data?.listflight?.flight[
                          params?.data?.listflight?.flight?.length - 1
                        ]?.arrivetime
                      : params?.data?.listflight?.flight?.arrivetime}{" "}
                    <span className="fw-light small">
                      {params?.data?.listflight?.flight?.length > 1 &&
                        params?.data?.listflight?.flight[0]?.arrivaldate !==
                          params?.data?.listflight?.flight[
                            params?.data?.listflight?.flight?.length - 1
                          ]?.arrivaldate &&
                        "(+1 D)"}
                    </span>
                  </div>
                  {/* <Badge className="mt-2" bg="grey">
                    {params?.data?.listflight?.flight?.tocity}
                  </Badge> */}
                  {/* <Badge className="mt-2" bg="grey">
                    {params?.data?.listflight?.flight?.length > 1
                      ? params?.data?.listflight?.flight[0]?.tocity
                      : params?.data?.listflight?.flight?.tocity}
                  </Badge> */}
                  <Badge className="mt-2" bg="grey">
                    {params?.data?.listflight?.flight?.length > 1
                      ? params?.data?.listflight?.flight[
                          params?.data?.listflight?.flight?.length - 1
                        ]?.tocity
                      : params?.data?.listflight?.flight?.tocity}
                  </Badge>
                </div>
                <div className="d-flex align-items-center facility-icon">
                  {Array?.isArray(params?.data?.listclassgroup?.classgroup)
                    ? params?.data?.listclassgroup?.classgroup?.find(
                        (o) => parseInt(o?.class?.baggagekilos) > 0
                      ) && (
                        <Image
                          src={BaggageIcon}
                          className="img-responsive me-2"
                        />
                      )
                    : parseInt(
                        params?.data?.listclassgroup?.classgroup?.class
                          ?.baggagekilos
                      ) > 0 && (
                        <Image
                          src={BaggageIcon}
                          className="img-responsive me-2"
                        />
                      )}

                  {Array?.isArray(params?.data?.listflight?.flight)
                    ? params?.data?.listflight?.flight?.find(
                        (o) => o?.meal === "true"
                      ) && (
                        <Image
                          src={MealsIcon}
                          className="img-responsive me-2"
                        />
                      )
                    : params?.data?.listflight?.flight?.meal === "true" && (
                        <Image
                          src={MealsIcon}
                          className="img-responsive me-2"
                        />
                      )}
                </div>
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="3" xl="3" className="text-end">
              <div className="flex-flight-button mx-4 mx-sm-0">
                <div className="text-grey mb-0 mb-sm-3">
                  <span className="fs-5 text-primary">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(
                      params?.data?.listclassgroup?.classgroup?.length > 1
                        ? params?.data?.listclassgroup?.classgroup?.reduce(
                            (accumulator, current) =>
                              parseInt(accumulator?.class?.fare) +
                              parseInt(accumulator?.class?.servicefee) +
                              (parseInt(current?.class?.fare) +
                                parseInt(current?.class?.servicefee))
                          )
                        : parseInt(
                            params?.data?.listclassgroup?.classgroup?.class
                              ?.fare
                          ) +
                            parseInt(
                              params?.data?.listclassgroup?.classgroup?.class
                                ?.servicefee
                            )
                    )}
                  </span>
                  /pax
                </div>
                <Button
                  variant="primary"
                  className="text-white"
                  onClick={() => {
                    params.setChoose(params?.data);
                    params.setTrigger(true);
                  }}
                >
                  Choose
                </Button>
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Tabs
                className="tab-flight-detail my-3 mx-auto"
                activeKey={key}
                onSelect={(k) => (k === key ? setKey("") : setKey(k))}
                justify
              >
                <Tab eventKey="home" title="Flight Details">
                  <Card className="bg-light mb-3">
                    <Card.Body className="py-2 px-3">
                      <div className="timeline">
                        {params?.data?.listflight?.flight?.length > 1 ? (
                          params?.data?.listflight?.flight?.map(
                            (item, index) => (
                              <div key={index}>
                                <div className="timeline-flight">
                                  <div className="item">
                                    <div className="date-time">
                                      <div className="time">
                                        {item?.departtime}
                                      </div>
                                      <div className="date">
                                        {moment(item?.departdate).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </div>
                                      <div className="duration-left">
                                        <div className="d-flex">
                                          <Image
                                            src={ClockIcon}
                                            className="mx-1"
                                          />
                                          <div className="fw-light">
                                            {item?.durationhour}j{" "}
                                            {item?.durationminute}m
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="event">
                                      <div className="title">
                                        {CityConverter(item?.fromcity)} (
                                        {item?.fromcity})
                                      </div>
                                      <div className="description">
                                        {AirportConverter(item?.fromcity)}
                                      </div>
                                      <div className="flight">
                                        {AirlineImageConverter(
                                          item?.flightno.substring(0, 2)
                                        )}
                                        {item?.flightno} {" | "}
                                        {AirlineNameConverter(
                                          item?.flightno?.substring(0, 2)
                                        )}
                                        {" | "}
                                        {params?.dataBase?.cabin
                                          ?.replace(/-/g, " ")
                                          .replace(
                                            /(^\w{1})|(\s+\w{1})/g,
                                            (letter) => letter?.toUpperCase()
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="date-time">
                                      <div className="time">
                                        {item?.arrivetime}
                                      </div>
                                      <div className="date">
                                        {moment(item?.arrivaldate).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="event">
                                      <div className="title">
                                        {CityConverter(item?.tocity)} (
                                        {item?.tocity})
                                      </div>
                                      <div className="description">
                                        {AirportConverter(item?.tocity)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {index !==
                                  params?.data?.listflight?.flight?.length -
                                    1 && (
                                  <div className="w-100 text-center my-4">
                                    <div className="flight">Transit</div>
                                  </div>
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <div className="timeline-flight">
                            <div className="item">
                              <div className="date-time">
                                <div className="time">
                                  {params?.data?.listflight?.flight?.departtime}
                                </div>
                                <div className="date">
                                  {moment(
                                    params?.data?.listflight?.flight?.departdate
                                  ).format("ddd, DD MMM YYYY")}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(
                                    params?.data?.listflight?.flight?.fromcity
                                  )}{" "}
                                  ({params?.data?.listflight?.flight?.fromcity})
                                </div>
                                <div className="description">
                                  {AirportConverter(
                                    params?.data?.listflight?.flight?.fromcity
                                  )}
                                </div>
                                <div className="flight">
                                  {AirlineImageConverter(
                                    params?.data?.listflight?.flight?.flightno.substring(
                                      0,
                                      2
                                    )
                                  )}
                                  {params?.data?.listflight?.flight?.flightno}
                                  {" | "}
                                  {AirlineNameConverter(
                                    params?.data?.listflight?.flight?.flightno?.substring(
                                      0,
                                      2
                                    )
                                  )}
                                  {" | "}
                                  {params?.dataBase?.cabin
                                    ?.replace(/-/g, " ")
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter?.toUpperCase()
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="date-time">
                                <div className="time">
                                  {params?.data?.listflight?.flight?.arrivetime}
                                </div>
                                <div className="date">
                                  {moment(
                                    params?.data?.listflight?.flight
                                      ?.arrivaldate
                                  ).format("ddd, DD MMM YYYY")}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(
                                    params?.data?.listflight?.flight?.tocity
                                  )}{" "}
                                  ({params?.data?.listflight?.flight?.tocity})
                                </div>
                                <div className="description">
                                  {AirportConverter(
                                    params?.data?.listflight?.flight?.tocity
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Tab>
                <Tab eventKey="profile" title="Price Details">
                  <ul>
                    {params?.dataBase?.adult !== "0" && (
                      <li>
                        <div className="d-flex align-items-center justify-content-between mb-3 fw-light">
                          <div>
                            {t("adult")} (x{params?.dataBase?.adult})
                          </div>
                          <div>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            }).format(
                              params?.data?.listclassgroup?.classgroup?.length >
                                1
                                ? params?.data?.listclassgroup?.classgroup?.reduce(
                                    (accumulator, current) =>
                                      parseInt(accumulator?.class?.fare) +
                                      parseInt(accumulator?.class?.servicefee) +
                                      (parseInt(current?.class?.fare) +
                                        parseInt(current?.class?.servicefee))
                                  )
                                : parseInt(
                                    params?.data?.listclassgroup?.classgroup
                                      ?.class?.fare
                                  ) +
                                    parseInt(
                                      params?.data?.listclassgroup?.classgroup
                                        ?.class?.servicefee
                                    )
                            )}
                          </div>
                        </div>
                      </li>
                    )}
                    {params?.dataBase?.child !== "0" && (
                      <li>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="fw-light">
                            Children (x{params?.dataBase?.child})
                          </div>
                          <div>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            }).format(
                              params?.data?.listclassgroup?.classgroup?.length >
                                1
                                ? params?.data?.listclassgroup?.classgroup?.reduce(
                                    (accumulator, current) =>
                                      parseInt(accumulator?.class?.fare) +
                                      parseInt(accumulator?.class?.servicefee) +
                                      (parseInt(current?.class?.fare) +
                                        parseInt(current?.class?.servicefee))
                                  )
                                : parseInt(
                                    params?.data?.listclassgroup?.classgroup
                                      ?.class?.fare
                                  ) +
                                    parseInt(
                                      params?.data?.listclassgroup?.classgroup
                                        ?.class?.servicefee
                                    )
                            )}
                          </div>
                        </div>
                      </li>
                    )}
                    {params?.dataBase?.infant !== "0" && (
                      <li>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="fw-light">
                            Baby (x{params?.dataBase?.infant})
                          </div>
                          <div>
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            }).format(
                              params?.data?.listclassgroup?.classgroup?.length >
                                1
                                ? params?.data?.listclassgroup?.classgroup?.reduce(
                                    (accumulator, current) =>
                                      parseInt(accumulator?.class?.fare) +
                                      parseInt(accumulator?.class?.servicefee) +
                                      (parseInt(current?.class?.fare) +
                                        parseInt(current?.class?.servicefee))
                                  )
                                : parseInt(
                                    params?.data?.listclassgroup?.classgroup
                                      ?.class?.fare
                                  ) +
                                    parseInt(
                                      params?.data?.listclassgroup?.classgroup
                                        ?.class?.servicefee
                                    )
                            )}
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                  <hr></hr>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="fw-light">{t("total_payment")}</div>
                    <div className="fw-light">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(
                        (params?.data?.listclassgroup?.classgroup?.length > 1
                          ? params?.data?.listclassgroup?.classgroup?.reduce(
                              (accumulator, current) =>
                                parseInt(accumulator?.class?.fare) +
                                parseInt(accumulator?.class?.servicefee) +
                                (parseInt(current?.class?.fare) +
                                  parseInt(current?.class?.servicefee))
                            )
                          : parseInt(
                              params?.data?.listclassgroup?.classgroup?.class
                                ?.fare
                            ) +
                            parseInt(
                              params?.data?.listclassgroup?.classgroup?.class
                                ?.servicefee
                            )) *
                          parseInt(params?.dataBase?.adult) +
                          parseInt(params?.dataBase?.child) +
                          parseInt(params?.dataBase?.infant)
                      )}
                    </div>
                  </div>
                </Tab>
                {/* <Tab eventKey="contact" title="Policy">
                  c
                </Tab> */}
              </Tabs>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
}
export default ItemFlight;
