import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import RightArrowIcon from "../../../assets/icons/rightArrow.svg";
import ArrowFlightSingle from "../../../assets/icons/arrow-flight-single.svg";
import ArrowFlightDouble from "../../../assets/icons/arrow-flight-double.svg";
import InsuranceIcon from "../../../assets/icons/insurance.svg";
import ClockIcon from "../../../assets/icons/clock.svg";
import PlaneIcon from "../../../assets/icons/plane.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import ReactSelect from "react-select";
import PassangerCard from "./components/passanger";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../services/user";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import PassangerForm from "./components/passangerForm";
import {
  AirlineConverter,
  AirlineImageConverter,
  AirportConverter,
  CityConverter,
} from "../../../config/helper/maskapai";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    firstName: yup
      .string()
      .required("First Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    lastName: yup
      .string()
      .required("Last Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    phoneNumber: yup.string().required("Phone Number is required"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    customers: yup.array(
      yup.object({
        salutation: yup.string().required("Title is required"),
        firstName: yup
          .string()
          .required("First Name is required")
          .matches(
            /^[aA-zZ\s]+$/,
            "Only alphabets are allowed for this field "
          ),
        lastName: yup
          .string()
          .required("Last Name is required")
          .matches(
            /^[aA-zZ\s]+$/,
            "Only alphabets are allowed for this field "
          ),
        idCard: yup.string().required("ID Card Number is required"),
      })
    ),
  })
  .required();

function BookingFormDesktopPage() {
  const { t } = useTranslation();
  const { data, addMaster, addMasterNew, payment } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [insurance, setInsurance] = useState(0);
  const [modal, setModal] = useState({
    open: false,
    type: "",
  });
  const [dataSalutation, setDataSalutation] = useState({
    adult: [
      {
        value: "MR",
        label: "Mr",
      },
      {
        value: "MS",
        label: "Ms",
      },
      {
        value: "MRS",
        label: "Mrs",
      },
    ],
    child: [
      {
        value: "MSTR",
        label: "Mister",
      },
      {
        value: "MISS",
        label: "Miss",
      },
    ],
    infant: [
      {
        value: "INF",
        label: "Infant",
      },
    ],
  });
  const [dataCountryCode, setDataCountryCode] = useState([
    {
      value: "62",
      label: "+62",
    },
  ]);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: localStorage.getItem("title")?.toUpperCase(),
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
      countryCode: localStorage.getItem("countryCode"),
      phoneNumber: localStorage.getItem("phone"),
      email: localStorage.getItem("email"),
    },
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const pax = values.customers?.map((item) => ({
      title: item.salutation,
      firstname: item.firstName,
      lastname: item.lastName,
      adultassoc:
        item.type === "ADULT" && parseInt(data?.searchParams?.infant) > 0
          ? true
          : "",
      type: item.type?.toUpperCase(),
      id: item.idCard,
      dob: item.bornDate && moment(item.bornDate).format("DD-MMM-YYYY"),
      passport: {
        no: "",
        nationality: "ID",
        issuingcountry: "",
        expiry: "",
      },
    }));
    let trip = [];

    [data?.departure, data?.destination].map((item, index) => {
      if (item) {
        trip.push({
          $: {
            type: index === 0 ? "departure" : "return",
          },
          airlinecode:
            item?.listflight?.flight?.length > 1
              ? item?.listflight?.flight[0]?.airlinecode
              : item?.listflight?.flight?.airlinecode,
          tripdate:
            index === 0
              ? data?.searchParams?.departdate
              : data?.searchParams?.returndate,
          listselecteditem: {
            selecteditem: {
              sumlocal: {
                segment:
                  item?.listflight?.flight?.length > 1
                    ? item?.listflight?.flight?.map((subItem, subIndex) => ({
                        flightno: subItem?.flightno,
                        fromcity: subItem?.fromcity,
                        tocity: subItem?.tocity,
                        classcode:
                          item?.listclassgroup?.classgroup[subIndex]?.class
                            ?.code,
                        departtime: subItem?.departtime,
                        arrivetime: subItem?.arrivetime,
                        departdate: subItem?.departdate,
                      }))
                    : [
                        {
                          flightno: item?.listflight?.flight?.flightno,
                          fromcity: item?.listflight?.flight?.fromcity,
                          tocity: item?.listflight?.flight?.tocity,
                          classcode:
                            item?.listclassgroup?.classgroup?.class?.code,
                          departtime: item?.listflight?.flight?.departtime,
                          arrivetime: item?.listflight?.flight?.arrivetime,
                          departdate: item?.listflight?.flight?.departdate,
                        },
                      ],
              },
            },
          },
        });
      }
    });

    let dataSend = {
      selectionrequest: [
        {
          withinsurance: values?.insurance === "true" ? true : false,
          adultcount: parseInt(data?.searchParams?.adult),
          childcount: parseInt(data?.searchParams?.child),
          infantcount: parseInt(data?.searchParams?.infant),
          trip: trip,
        },
      ],
      listpax: {
        pax: pax,
      },
      paxcontact: {
        firstname: values?.firstName,
        lastname: values?.lastName,
        phone1: values?.phoneNumber,
      },
    };

    const newData = await addMaster("voltras/flight-booking", dataSend);
    if (!newData?.err) {
      data.payment = newData?.data;
      data.pax = pax;
      data.insurance = insurance;
      const newData2 = await payment(data);
      if (newData2) {
        setLoading(false);
        navigate("/flights/payment");
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getDataPayment = async () => {
    let trip = [];

    [data?.departure, data?.destination].map((item, index) => {
      if (item) {
        trip.push({
          $: {
            type: index === 0 ? "departure" : "return",
          },
          airlinecode:
            item?.listflight?.flight?.length > 1
              ? item?.listflight?.flight[0]?.airlinecode
              : item?.listflight?.flight?.airlinecode,
          tripdate:
            index === 0
              ? data?.searchParams?.departdate
              : data?.searchParams?.returndate,
          listselecteditem: {
            selecteditem: {
              sumlocal: {
                segment:
                  item?.listflight?.flight?.length > 1
                    ? item?.listflight?.flight?.map((subItem, subIndex) => ({
                        flightno: subItem?.flightno,
                        fromcity: subItem?.fromcity,
                        tocity: subItem?.tocity,
                        classcode:
                          item?.listclassgroup?.classgroup[subIndex]?.class
                            ?.code,
                        departtime: subItem?.departtime,
                        arrivetime: subItem?.arrivetime,
                        departdate: subItem?.departdate,
                      }))
                    : [
                        {
                          flightno: item?.listflight?.flight?.flightno,
                          fromcity: item?.listflight?.flight?.fromcity,
                          tocity: item?.listflight?.flight?.tocity,
                          classcode:
                            item?.listclassgroup?.classgroup?.class?.code,
                          departtime: item?.listflight?.flight?.departtime,
                          arrivetime: item?.listflight?.flight?.arrivetime,
                          departdate: item?.listflight?.flight?.departdate,
                        },
                      ],
              },
            },
          },
        });
      }
    });

    const dataCheck = {
      request: {
        withinsurance: getValues("insurance") === "true" ? true : false,
        adultcount: parseInt(data?.searchParams?.adult),
        childcount: parseInt(data?.searchParams?.child),
        infantcount: parseInt(data?.searchParams?.infant),
        trip: trip,
      },
    };

    const newData = await addMasterNew("voltras/fare-retrieve", dataCheck);
    if (newData) {
      setInsurance(newData?.data?.response?.insurance);
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "insurance") {
        getDataPayment();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (data?.searchParams?.adult) {
      let passangerCard = [];
      for (let i = 1; i <= data?.searchParams?.adult; i++) {
        passangerCard.push({
          type: "adult",
          salutation: "",
          firstName: "",
          lastName: "",
          idCard: "",
          bornDate: "",
        });
      }
      for (let i = 1; i <= data?.searchParams?.child; i++) {
        passangerCard.push({
          type: "child",
          salutation: "",
          firstName: "",
          lastName: "",
          idCard: "",
          bornDate: "",
        });
      }
      for (let i = 1; i <= data?.searchParams?.infant; i++) {
        passangerCard.push({
          type: "infant",
          salutation: "",
          firstName: "",
          lastName: "",
          idCard: "",
          bornDate: "",
        });
      }
      setValue("customers", passangerCard);
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <DesktopWrapper>
      {loading ? (
        <div className="my-5">
          <BeatLoader
            color={"#ff5b00"}
            loading={loading}
            cssOverride={{
              display: "block",
              margin: "0 auto",
              borderColor: "red",
              textAlign: "center",
            }}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <div className="h4 text-center mt-4">Memproses Pemesanan</div>
        </div>
      ) : (
        <Container className="detail-product py-4">
          <Row>
            <Col sm="12" md="12" lg="8" xl="8">
              <Card className="border-0 shadow-sm mt-4">
                <Card.Body className="p-4">
                  <h5>{t("customer_data")}</h5>
                  <Row className="pt-3">
                    <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
                      <Controller
                        control={control}
                        {...register("title")}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            placeholder={t("title")}
                            isSearchable={false}
                            value={dataSalutation?.adult?.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => onChange(val.value)}
                            options={dataSalutation?.adult}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.title?.message}
                      </small>
                    </Col>
                    <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
                      <Form.Control
                        {...register("firstName")}
                        className="control-input"
                        placeholder="First Name*"
                      />
                      <small className="text-danger">
                        {errors.firstName?.message}
                      </small>
                    </Col>
                    <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
                      <Form.Control
                        {...register("lastName")}
                        className="control-input"
                        placeholder="Last Name*"
                      />
                      <small className="text-danger">
                        {errors.lastName?.message}
                      </small>
                    </Col>
                    <Col sm="12" md="2" lg="2" xl="2"></Col>
                    <Col sm="12" md="10" lg="10" xl="10" className="py-1 mb-2">
                      <small className="fw-light text-grey">
                        {t("as_on_id_card")}
                      </small>
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
                      <Controller
                        control={control}
                        name="countryCode"
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            isSearchable={false}
                            placeholder="+xx"
                            value={dataCountryCode?.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => onChange(val.value)}
                            options={dataCountryCode}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.countryCode?.message}
                      </small>
                    </Col>
                    <Col sm="12" md="10" lg="10" xl="10" className="mb-2">
                      <Form.Control
                        {...register("phoneNumber")}
                        className="control-input"
                        placeholder={t("phone_number") + "*"}
                      />
                      <small className="text-danger">
                        {errors.phoneNumber?.message}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="12" lg="12" xl="12" className="mb-2">
                      <Form.Control
                        {...register("email")}
                        className="control-input"
                        placeholder={t("email_address") + "*"}
                      />
                      <small className="text-danger">
                        {errors.email?.message}
                      </small>
                    </Col>
                    <Col sm="12" md="12" lg="12" xl="12" className="py-1">
                      <small className="fw-light text-grey">
                        {t("eticket_will_be_sent_to_this_email_address")}
                      </small>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="border-0 shadow-sm mt-4">
                <Card.Body className="p-4">
                  <h5>Passanger Data</h5>
                  <PassangerForm
                    {...{
                      control,
                      register,
                      errors,
                      setValue,
                      getValues,
                      dataSalutation,
                      dataCountryCode,
                    }}
                  />
                </Card.Body>
              </Card>

              <div className="mt-3 text-end">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  className="text-white px-4 py-2"
                  variant="primary"
                  disabled={loading}
                >
                  {t("continue_to_payment")}
                </Button>
              </div>
              <div className="fs-18">Additional Protection</div>

              <Card className="border-0 shadow-sm mt-4">
                <Card.Body className="p-4 d-flex">
                  <div className="me-4">
                    <Image src={InsuranceIcon} alt="" />
                  </div>
                  <div className="me-3">
                    <div className="fw-bold mb-3">Travel Insurance</div>
                    <div className="text-primary mb-3">Rp 10.000</div>
                    <div className="fs-14 fw-light text-grey">
                      Travel insurance guarantees and provides compensation for
                      losses during a series of flights experienced by the
                      Insured.
                    </div>
                  </div>
                  <div>
                    <input
                      name="insurance"
                      {...register("insurance")}
                      value={true}
                      type="checkbox"
                      className="check-insurance"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" md="12" lg="4" xl="4" className="mt-4">
              <Card className="border-0 shadow-sm mt-4">
                <Card.Body className="p-3">
                  <div className="text-muted">Flight Detail</div>
                  <div className="py-2 mt-3 d-flex align-items-center text-dark-grey small fw-light">
                    <img src={PlaneIcon} className="me-3" alt="" />
                    <div>{data?.searchParams?.fromcity}</div>
                    <Image
                      src={
                        data?.destination
                          ? ArrowFlightDouble
                          : ArrowFlightSingle
                      }
                      className="mx-3"
                    />
                    <div>{data?.searchParams?.tocity}</div>
                  </div>
                  <div className="my-2 px-2 py-3 border rounded small">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="text-normal">
                        {moment(data?.searchParams?.departdate).format(
                          "ddd, DD MMM YYYY"
                        )}
                      </div>
                      <div
                        className="text-primary"
                        role="button"
                        onClick={() =>
                          setModal({ open: true, type: "departure" })
                        }
                      >
                        Detail
                      </div>
                    </div>
                    <div className="d-flex justify-content-between detail-flight">
                      <div>
                        {data?.departure?.listflight?.flight?.length > 1
                          ? data?.departure?.listflight?.flight?.map((item) => (
                              <div className="mb-2">
                                {AirlineConverter(
                                  item.flightno.substring(0, 2)
                                )}
                              </div>
                            ))
                          : AirlineConverter(
                              data?.departure?.listflight?.flight?.flightno.substring(
                                0,
                                2
                              )
                            )}
                      </div>
                      <div className="d-flex">
                        <div className="d-flex text-center align-items-center justify-content-around my-auto">
                          <div>
                            <div>
                              {data?.departure?.listflight?.flight?.length > 1
                                ? data?.departure?.listflight?.flight[0]
                                    ?.departtime
                                : data?.departure?.listflight?.flight
                                    ?.departtime}
                            </div>
                          </div>
                          <div className="mx-4 fw-lighter small">
                            <img src={ArrowRightIcon} alt="" />
                          </div>
                          <div>
                            <div>
                              {data?.departure?.listflight?.flight?.length > 1
                                ? data?.departure?.listflight?.flight[
                                    data?.departure?.listflight?.flight
                                      ?.length - 1
                                  ]?.arrivetime
                                : data?.departure?.listflight?.flight
                                    ?.arrivetime}{" "}
                              <span className="fw-light small">
                                {data?.departure?.listflight?.flight?.length >
                                  1 &&
                                  data?.departure?.listflight?.flight[0]
                                    ?.arrivaldate !==
                                    data?.departure?.listflight?.flight[
                                      data?.departure?.listflight?.flight
                                        ?.length - 1
                                    ]?.arrivaldate &&
                                  "(+1 D)"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between small text-muted fw-light">
                      <div className="d-flex align-items-center">
                        <div>
                          {data?.departure?.listflight?.flight?.length > 1
                            ? data?.departure?.listflight?.flight?.length +
                              " Transit"
                            : "Direct"}
                        </div>
                        <div className="mx-2">&#x2022;</div>
                        <div>
                          {data?.searchParams?.cabin
                            ?.replace(/-/g, " ")
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                              letter?.toUpperCase()
                            )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="badge-city">
                          {data?.searchParams?.fromcity}
                        </div>
                        <div className="px-2 small">
                          {data?.departure?.durationhour}j{" "}
                          {data?.departure?.durationminute}m
                        </div>
                        <div className="badge-city">
                          {data?.searchParams?.tocity}
                        </div>
                      </div>
                    </div>
                  </div>
                  {data?.destination && (
                    <div className="my-2 px-2 py-3 border rounded small">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="text-normal">
                          {moment(data?.searchParams?.arrivaldate).format(
                            "ddd, DD MMM YYYY"
                          )}
                        </div>
                        <div
                          className="text-primary"
                          role="button"
                          onClick={() =>
                            setModal({ open: true, type: "destination" })
                          }
                        >
                          Detail
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {data?.destination?.listflight?.flight?.length > 1
                            ? data?.destination?.listflight?.flight?.map(
                                (item) => (
                                  <div className="mb-2">
                                    {AirlineConverter(
                                      item.flightno.substring(0, 2)
                                    )}
                                  </div>
                                )
                              )
                            : AirlineConverter(
                                data?.destination?.listflight?.flight?.flightno.substring(
                                  0,
                                  2
                                )
                              )}
                        </div>
                        <div className="d-flex">
                          <div className="d-flex text-center align-items-center justify-content-around my-auto">
                            <div>
                              <div>
                                {data?.destination?.listflight?.flight?.length >
                                1
                                  ? data?.destination?.listflight?.flight[0]
                                      ?.departtime
                                  : data?.destination?.listflight?.flight
                                      ?.departtime}
                              </div>
                            </div>
                            <div className="mx-4 fw-lighter small">
                              <img src={ArrowRightIcon} alt="" />
                            </div>
                            <div>
                              <div>
                                {data?.destination?.listflight?.flight?.length >
                                1
                                  ? data?.destination?.listflight?.flight
                                      ?.length + " Transit"
                                  : "Direct"}
                              </div>
                              <div className="mt-2">
                                {data?.searchParams?.cabin
                                  ?.replace(/-/g, " ")
                                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                    letter?.toUpperCase()
                                  )}
                              </div>
                            </div>
                            <div>
                              <div className="fw-bold">
                                {data?.destination?.listflight?.flight?.length >
                                1
                                  ? data?.destination?.listflight?.flight[
                                      data?.destination?.listflight?.flight
                                        ?.length - 1
                                    ]?.arrivetime
                                  : data?.destination?.listflight?.flight
                                      ?.arrivetime}{" "}
                                <span className="fw-light small">
                                  {data?.destination?.listflight?.flight
                                    ?.length > 1 &&
                                    data?.destination?.listflight?.flight[0]
                                      ?.arrivaldate !==
                                      data?.destination?.listflight?.flight[
                                        data?.destination?.listflight?.flight
                                          ?.length - 1
                                      ]?.arrivaldate &&
                                    "(+1 D)"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between small text-muted fw-light">
                        <div className="d-flex align-items-center">
                          <div>
                            {data?.destination?.listflight?.flight?.length > 1
                              ? data?.destination?.listflight?.flight?.length +
                                " Transit"
                              : "Direct"}
                          </div>
                          <div className="mx-2">&#x2022;</div>
                          <div>
                            {data?.searchParams?.cabin
                              ?.replace(/-/g, " ")
                              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter?.toUpperCase()
                              )}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="badge-city">
                            {data?.searchParams?.tocity}
                          </div>
                          <div className="px-2 small">
                            {data?.destination?.durationhour}j{" "}
                            {data?.destination?.durationminute}m
                          </div>
                          <div className="badge-city">
                            {data?.searchParams?.fromcity}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-between py-2">
                    <div className="d-flex mt-3 align-item-center justify-content-between w-100">
                      <div className="text-muted">{t("total_price")}</div>
                      <div className="text-primary fs-5 fw-bold d-flex align-items-between">
                        <div className="pe-2">
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(
                            data?.total *
                              (parseInt(data?.searchParams?.adult) +
                                parseInt(data?.searchParams?.child) +
                                parseInt(data?.searchParams?.infant)) +
                              parseInt(insurance)
                          )}
                        </div>
                        <Image
                          src={RightArrowIcon}
                          style={{ transform: "rotate(90deg)" }}
                          role="button"
                          onClick={() => setShowPrice(true)}
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={showPrice}
            onHide={() => setShowPrice(false)}
            size="sm"
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="text-muted">{t("price")}</div>
                  <div>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(data?.total)}{" "}
                    x{" "}
                    {parseInt(data?.searchParams?.adult) +
                      parseInt(data?.searchParams?.child) +
                      parseInt(data?.searchParams?.infant)}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="text-muted">{t("price")}</div>
                  <div>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(
                      parseInt(insurance) /
                        parseInt(data?.searchParams?.adult) +
                        parseInt(data?.searchParams?.child) +
                        parseInt(data?.searchParams?.infant)
                    )}{" "}
                    x{" "}
                    {parseInt(data?.searchParams?.adult) +
                      parseInt(data?.searchParams?.child) +
                      parseInt(data?.searchParams?.infant)}
                  </div>
                </div>
                <hr></hr>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-bold">{t("total_price")}</div>
                  <div className="fw-bold">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(
                      data?.total *
                        (parseInt(data?.searchParams?.adult) +
                          parseInt(data?.searchParams?.child) +
                          parseInt(data?.searchParams?.infant)) +
                        parseInt(insurance)
                    )}
                  </div>
                </div>
              </div>
              <Button
                className="text-white w-100"
                onClick={() => setShowPrice(false)}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={modal?.open}
            onHide={() => setModal({ open: false, type: "" })}
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="mb-4">
                <div className="text-muted mb-3">Flight Detail</div>
                <div>
                  {modal?.type === "departure" && (
                    <div>
                      <div className="mb-3 d-flex align-items-center justify-content-between small">
                        <div className="py-2 d-flex align-items-center">
                          <div>{data?.searchParams?.fromcity}</div>
                          <Image src={ArrowFlightSingle} className="mx-3" />
                          <div>{data?.searchParams?.tocity}</div>
                        </div>
                        <div className="d-flex">
                          <Image src={ClockIcon} className="mx-1" />
                          <div className="fw-light">
                            {data?.departure?.durationhour}j{" "}
                            {data?.departure?.durationminute}m
                          </div>
                        </div>
                      </div>
                      <div className="timeline">
                        {data?.departure?.listflight?.flight?.length > 1 ? (
                          data?.departure?.listflight?.flight?.map(
                            (item, index) => (
                              <>
                                <div className="timeline-flight">
                                  <div className="item">
                                    <div className="date-time">
                                      <div className="time">
                                        {item?.departtime}
                                      </div>
                                      <div className="date">
                                        {moment(item?.departdate).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="event">
                                      <div className="title">
                                        {CityConverter(item?.fromcity)} (
                                        {item?.fromcity})
                                      </div>
                                      <div className="description">
                                        {AirportConverter(item?.fromcity)}
                                      </div>
                                      <div className="flight">
                                        {AirlineImageConverter(
                                          item?.airlinecode
                                        )}
                                        {item?.flightno} {" | "}
                                        {data?.searchParams?.cabin
                                          ?.replace(/-/g, " ")
                                          .replace(
                                            /(^\w{1})|(\s+\w{1})/g,
                                            (letter) => letter?.toUpperCase()
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="date-time">
                                      <div className="time">
                                        {item?.arrivetime}
                                      </div>
                                      <div className="date">
                                        {moment(item?.arrivaldate).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="event">
                                      <div className="title">
                                        {CityConverter(item?.tocity)} (
                                        {item?.fromcity})
                                      </div>
                                      <div className="description">
                                        {AirportConverter(item?.tocity)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {index !==
                                  data?.departure?.listflight?.flight?.length -
                                    1 && (
                                  <div className="w-100 text-center my-4">
                                    <div className="flight">Transit</div>
                                  </div>
                                )}
                              </>
                            )
                          )
                        ) : (
                          <div className="timeline-flight">
                            <div className="item">
                              <div className="date-time">
                                <div className="time">
                                  {
                                    data?.departure?.listflight?.flight
                                      ?.departtime
                                  }
                                </div>
                                <div className="date">
                                  {moment(
                                    data?.departure?.listflight?.flight
                                      ?.departdate
                                  ).format("ddd, DD MMM YYYY")}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(
                                    data?.departure?.listflight?.flight
                                      ?.fromcity
                                  )}{" "}
                                  (
                                  {
                                    data?.departure?.listflight?.flight
                                      ?.fromcity
                                  }
                                  )
                                </div>
                                <div className="description">
                                  {AirportConverter(
                                    data?.departure?.listflight?.flight
                                      ?.fromcity
                                  )}
                                </div>
                                <div className="flight">
                                  {AirlineImageConverter(
                                    data?.departure?.listflight?.flight
                                      ?.airlinecode
                                  )}
                                  {
                                    data?.departure?.listflight?.flight
                                      ?.flightno
                                  }
                                  {" | "}
                                  {data?.searchParams?.cabin
                                    ?.replace(/-/g, " ")
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter?.toUpperCase()
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="date-time">
                                <div className="time">
                                  {
                                    data?.departure?.listflight?.flight
                                      ?.arrivetime
                                  }
                                </div>
                                <div className="date">
                                  {moment(
                                    data?.departure?.listflight?.flight
                                      ?.arrivaldate
                                  ).format("ddd, DD MMM YYYY")}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(
                                    data?.departure?.listflight?.flight?.tocity
                                  )}{" "}
                                  ({data?.departure?.listflight?.flight?.tocity}
                                  )
                                </div>
                                <div className="description">
                                  {AirportConverter(
                                    data?.departure?.listflight?.flight?.tocity
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {modal?.type === "destination" && (
                    <div>
                      <div className="mb-3 d-flex align-items-center justify-content-between small">
                        <div className="py-2 d-flex align-items-center">
                          <div>{data?.searchParams?.tocity}</div>
                          <Image src={ArrowFlightSingle} className="mx-3" />
                          <div>{data?.searchParams?.fromcity}</div>
                        </div>
                        <div className="d-flex">
                          <Image src={ClockIcon} className="mx-1" />
                          <div className="fw-light">
                            {data?.destination?.durationhour}j{" "}
                            {data?.destination?.durationminute}m
                          </div>
                        </div>
                      </div>
                      <div className="timeline">
                        {data?.destination?.listflight?.flight?.length > 1 ? (
                          data?.destination?.listflight?.flight?.map(
                            (item, index) => (
                              <div key={index}>
                                <div className="timeline-flight">
                                  <div className="item">
                                    <div className="date-time">
                                      <div className="time">
                                        {item?.departtime}
                                      </div>
                                      <div className="date">
                                        {moment(item?.departdate).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="event">
                                      <div className="title">
                                        {CityConverter(item?.fromcity)} (
                                        {item?.fromcity})
                                      </div>
                                      <div className="description">
                                        {AirportConverter(item?.fromcity)}
                                      </div>
                                      <div className="flight">
                                        {AirlineImageConverter(
                                          item?.airlinecode
                                        )}
                                        {item?.flightno} {" | "}
                                        {data?.searchParams?.cabin
                                          ?.replace(/-/g, " ")
                                          .replace(
                                            /(^\w{1})|(\s+\w{1})/g,
                                            (letter) => letter?.toUpperCase()
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="item">
                                    <div className="date-time">
                                      <div className="time">
                                        {item?.arrivetime}
                                      </div>
                                      <div className="date">
                                        {moment(item?.arrivaldate).format(
                                          "ddd, DD MMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="event">
                                      <div className="title">
                                        {CityConverter(item?.arrival)} (
                                        {item?.arrival})
                                      </div>
                                      <div className="description">
                                        {AirportConverter(item?.arrival)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {index !==
                                  data?.destination?.listflight?.flight
                                    ?.length -
                                    1 && (
                                  <div className="w-100 text-center my-4">
                                    <div className="flight">Transit</div>
                                  </div>
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <div className="timeline-flight">
                            <div className="item">
                              <div className="date-time">
                                <div className="time">
                                  {
                                    data?.destination?.listflight?.flight
                                      ?.departtime
                                  }
                                </div>
                                <div className="date">
                                  {moment(
                                    data?.destination?.listflight?.flight
                                      ?.departdate
                                  ).format("ddd, DD MMM YYYY")}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(
                                    data?.destination?.listflight?.flight
                                      ?.fromcity
                                  )}{" "}
                                  (
                                  {
                                    data?.destination?.listflight?.flight
                                      ?.fromcity
                                  }
                                  )
                                </div>
                                <div className="description">
                                  {AirportConverter(
                                    data?.destination?.listflight?.flight
                                      ?.fromcity
                                  )}
                                </div>
                                <div className="flight">
                                  {AirlineImageConverter(
                                    data?.departure?.listflight?.flight
                                      ?.airlinecode
                                  )}
                                  {
                                    data?.destination?.listflight?.flight
                                      ?.flightno
                                  }
                                  {" | "}
                                  {data?.searchParams?.cabin
                                    ?.replace(/-/g, " ")
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter?.toUpperCase()
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <div className="date-time">
                                <div className="time">
                                  {
                                    data?.destination?.listflight?.flight
                                      ?.arrivetime
                                  }
                                </div>
                                <div className="date">
                                  {moment(
                                    data?.destination?.listflight?.flight
                                      ?.arrivaldate
                                  ).format("ddd, DD MMM YYYY")}
                                </div>
                              </div>
                              <div className="event">
                                <div className="title">
                                  {CityConverter(
                                    data?.destination?.listflight?.flight
                                      ?.tocity
                                  )}{" "}
                                  (
                                  {
                                    data?.destination?.listflight?.flight
                                      ?.tocity
                                  }
                                  )
                                </div>
                                <div className="description">
                                  {AirportConverter(
                                    data?.destination?.listflight?.flight
                                      ?.tocity
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Button
                className="text-white w-100"
                onClick={() => setModal({ open: false, type: "" })}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </DesktopWrapper>
  );
}
export default BookingFormDesktopPage;
