import React, { useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import PigijoLogo from "../../assets/auth/pigijo.png";
import PigijoBlogLogo from "../../assets/PigijoBlog.svg";
import GlobeIcon from "../../assets/icons/globe.svg";
import IndonesiaFlag from "../../assets/icons/indonesiaFlag.svg";
import EnglishFlag from "../../assets/icons/englishFlag.svg";
import UserIcon from "../../assets/icons/user.svg";
import CloseIcon from "../../assets/icons/close.svg";
import MenuIcon from "../../assets/icons/menu.svg";
import DefaultImage from "../../assets/images/default.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoginModal from "../../component/desktop/modal/loginModal";
import RegisterModal from "../../component/desktop/modal/registerModal";
import SendEmailResetModal from "../../component/desktop/modal/forgetPasswordModal";
import ResendEmailModal from "../../component/desktop/modal/resendEmailModal";

function HeaderDesktop() {
  const { t, i18n } = useTranslation();
  const [showMobile, setShowMobile] = useState(false);
  const [show, setShow] = useState({
    type: "",
    open: false,
  });

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Navbar bg="light" expand="lg" sticky="top" className="py-2 shadow-sm">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image width="100%" src={PigijoLogo} />
        </Navbar.Brand>
        <div onClick={() => setShowMobile(true)} className="d-md-none d-block">
          <img src={MenuIcon} alt="" />
        </div>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <div
          className={"menu-mobile " + (showMobile ? "d-block" : "d-none")}
          id="basic-navbar-nav"
        >
          <div className="container-menu-mobile">
            <div className="menu-mobile-top d-flex align-items-center justify-content-between">
              <Dropdown>
                <Dropdown.Toggle
                  // as="div"
                  id="dropdown-basic"
                  className="btn btn-light p-2 m-2 rounded-pill"
                >
                  <Image src={GlobeIcon} /> <span>{t("language")}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as="div" onClick={() => changeLanguage("en")}>
                    <Image src={EnglishFlag} />
                    <span>English</span>
                  </Dropdown.Item>
                  <Dropdown.Item as="div" onClick={() => changeLanguage("id")}>
                    <Image src={IndonesiaFlag} />
                    <span>Bahasa Indonesia</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <img
                src={CloseIcon}
                alt=""
                onClick={() => setShowMobile(false)}
              />
            </div>
            <div className="menu-mobile-photo">
              <div className="menu-mobile-img">
                <img src={DefaultImage} alt="" />
              </div>
              <div className="menu-mobile-text">
                Hello,{" "}
                {localStorage.getItem("firstName")
                  ? localStorage.getItem("firstName") +
                    " " +
                    localStorage.getItem("lastName")
                  : t("guests")}
              </div>
            </div>
            <div className="menu-mobile-setting mt-5">
              {/* <div className="menu-mobile-setting-sub row mx-4 my-2"> */}
              {/* <div className="col-10 col-sm-10 pl-0 pr-0">
                  <div className="mb-1">Language Options</div>
                  <div className="small fw-light text-grey">
                    Language Indonesia or English
                  </div>
                </div>
                <div class="col-2 col-sm-1 pl-0 pr-0">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Check this switch"
                  ></Form.Check>
                </div> */}
              {/* </div> */}
              <Link
                to="/partners"
                className="menu-mobile-setting-sub row mx-4 my-2"
              >
                {t("become_our_partner")}
              </Link>
              <Link
                to="/privacy-policy"
                className="menu-mobile-setting-sub row mx-4 my-2"
              >
                {t("privacy_policy")}
              </Link>
              <Link
                to="/terms-and-conditions"
                className="menu-mobile-setting-sub row mx-4 my-2"
              >
                {t("terms_conditions")}
              </Link>
            </div>

            <div className="mt-5">
              {localStorage.getItem("role") === "member" ? (
                <Button
                  as={Link}
                  to="/profile"
                  className="menu-mobile-setting-sub row mx-4 my-2 text-white"
                >
                  {t("profile")}
                </Button>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    as={Link}
                    to="/login"
                    className="menu-mobile-setting-sub row mx-4 my-2 login-btn"
                  >
                    {t("login")}
                  </Button>
                  <Button
                    as={Link}
                    to="/register"
                    className="menu-mobile-setting-sub row mx-4 my-2 text-white"
                  >
                    {t("register")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Navbar.Collapse>
          <Nav className="me-auto">
            <a href="https://blog.pigijo.com/" target="_blank" rel="noreferrer">
              <Image width="100%" src={PigijoBlogLogo} />
            </a>
          </Nav>
          <Nav className="ms-auto">
            <Dropdown>
              <Dropdown.Toggle
                // as="div"
                id="dropdown-basic"
                className="btn btn-light p-2 m-2 rounded-pill"
              >
                <Image src={GlobeIcon} /> <span>{t("language")}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={() => changeLanguage("en")}>
                  <Image src={EnglishFlag} />
                  <span>English</span>
                </Dropdown.Item>
                <Dropdown.Item as="div" onClick={() => changeLanguage("id")}>
                  <Image src={IndonesiaFlag} />
                  <span>Bahasa Indonesia</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Item
              as={Link}
              to="/partners"
              className="btn btn-light rounded-pill p-2 m-2 fw-normal"
            >
              {t("become_partner")}
            </Nav.Item>
            {localStorage.getItem("role") === "member" ? (
              <Nav.Item
                className="btn btn-light rounded-pill p-2 m-2 fw-normal d-flex align-items-end"
                as={Link}
                to="/profile"
              >
                <Image src={UserIcon} />
                <span>{t("profile")}</span>
              </Nav.Item>
            ) : (
              <>
                <LoginModal setShow={setShow} show={show} t={t} />
                <RegisterModal setShow={setShow} show={show} t={t} />
                {/* <SendEmailResetModal setShow={setShow} show={show} t={t} />
                <ResendEmailModal setShow={setShow} show={show} t={t} /> */}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderDesktop;
